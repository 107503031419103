import React from 'react';
import { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { ERROR, OK, FOCUS, CLEAR } from './StatusIcon';
import * as EmailValidator from 'email-validator';

type Event = {
  target: {
    value: string,
  }
}

const EmailAddressField = ({value, allowEmpty, setStatus, onChange, ...props} : any) => {
  const [focused, setFocused] = useState(false);

  const update = (event: Event) => {
    onChange(event.target.value);
  };

  const validate = (email: string, allowEmpty: boolean) => {
    let status;
    if (email === '') {
      status = allowEmpty ? CLEAR : ERROR;
    } else {
      status = EmailValidator.validate(email) ? OK : ERROR;
    }
    return status;
  };

  useEffect(() => {
    const status = validate(value, allowEmpty);
    if (focused) {
      setStatus(status === ERROR ? FOCUS : status);
    } else {
      setStatus(status);
    }
  }, [value, allowEmpty, setStatus, focused]);

  return (
    <TextField
      value={value}
      onChange={update}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      {...props}
    />
  );
}

export default EmailAddressField;
