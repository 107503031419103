import React from 'react';
import Grid from '@material-ui/core/Grid';
import EmailIcon from '@material-ui/icons/Email';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    borderTop: 'solid 1px black',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  }
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid container item sm={5}>
        <Grid item sm={12} className={classes.icon}>
          <EmailIcon color="primary"/>
          &nbsp;
          reception@excelpsychology.com.au
        </Grid>
        <Grid item sm={12} className={classes.icon}>
          <LocalPhoneIcon color="primary"/>
          &nbsp;
          07 3868 2221
        </Grid>
      </Grid>
      <Grid item sm={2} className={classes.center}>
        <img src="/ExcelLogo-60x37.png" width="60" height="37" alt="Excel Logo"/>
      </Grid>
      <Grid item sm={5} className={classes.center}>
        For assistance please contact Excel Psychology reception.
      </Grid>
    </Grid>

  );
}

export default Footer;
