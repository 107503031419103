import Button from '@material-ui/core/Button';
import Pagination from './Pagination';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useState } from 'react';

const ContactPage = ({data, onComplete, classes} : any) => {
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = () => {
    onComplete('submit', {submitted: true});
    setSubmitted(true);
  }

  const submitForm = () => {
    return (
      <form className={classes.form} noValidate autoComplete="off">
        <div className={classes.textCenter}>
          <Typography variant="body2">
            You have completed the questionnaire.
          </Typography>
          <Typography variant="body2">
            To submit your results, please press the Submit button.
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>
        <Pagination onBack={()=>onComplete('back', {})}/>
      </form>

    );
  }

  const confirmation = () => {
    return (
      <form className={classes.form} noValidate autoComplete="off">
        <div className={classes.textCenter}>
          <Typography variant="body2">
            Thank you!
          </Typography>
          <Typography variant="body2">
            Your questionnaire has been submitted.
          </Typography>
        </div>
      </form>
    );
  }

  return submitted ? confirmation() : submitForm();
};

export default ContactPage;
