import React from 'react';
import { useState } from 'react';
import StatusIcon from './StatusIcon';
import ValidatedField from './ValidatedField';
import Pagination from './Pagination';
import { OK, CLEAR } from './StatusIcon';

const AddressPage = ({data, onComplete, classes} : any) => {
  const [address, setAddress] = useState(data.address);
  const [addressStatus, setAddressStatus] = useState(CLEAR);
  const [suburb, setSuburb] = useState(data.suburb);
  const [suburbStatus, setSuburbStatus] = useState(CLEAR);
  const [postCode, setPostCode] = useState(data.postCode);
  const [postCodeStatus, setPostCodeStatus] = useState(CLEAR);
  const [allowEmpty, setAllowEmpty] = useState(true);

  const complete = (action : string) => {
    if (action === 'next') {
      if (addressStatus !== OK ||
          suburbStatus !== OK ||
          postCodeStatus !== OK) {
        setAllowEmpty(false);
        return;
      }
    }

    const data = {
      address,
      suburb,
      postCode,
    }
    onComplete(action, data);
  }
  // intake form is https://drive.google.com/file/d/0BxhsAjye6VcMMDVMd2pkeURKTjA/view?ths=true
  return (
    <form className={classes.form} noValidate autoComplete="off">
      <div className={classes.formRow}>
        <ValidatedField
          label="Address"
          value={address}
          onChange={setAddress}
          setStatus={setAddressStatus}
          allowEmpty={allowEmpty}
          InputLabelProps={{ shrink: true, }}
        />
        <StatusIcon status={addressStatus}/>
      </div>
      <div className={classes.formRow}>
        <ValidatedField
          label="Suburb"
          value={suburb}
          onChange={setSuburb}
          setStatus={setSuburbStatus}
          allowEmpty={allowEmpty}
          InputLabelProps={{ shrink: true, }}
        />
        <StatusIcon status={suburbStatus}/>
      </div>
      <div className={classes.formRow}>
      <ValidatedField
        label="Postcode"
        value={postCode}
        onChange={setPostCode}
        setStatus={setPostCodeStatus}
        allowEmpty={allowEmpty}
        InputLabelProps={{ shrink: true, }}
      />
        <StatusIcon status={postCodeStatus}/>
      </div>

      <Pagination onBack={()=>complete('back')} onNext={()=>complete('next')}/>
    </form>

  );
};

export default AddressPage;
