import React from 'react';

const HomePage = () => {
  if (typeof window !== 'undefined') {
     window.location.href = "https://excelpsychology.com.au/";
   }
  return (
    <div/>
  );
};

export default HomePage;
