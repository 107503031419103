import React, { useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { getOrs, saveOrs } from '../../api/form';

import Alert from '@material-ui/lab/Alert';
import ErrorMessage from '../../components/ErrorMessage';
import Page from '../../components/Page';
import RangeQuestion from '../../components/RangeQuestion';
import SubmitPage from '../../components/SubmitPage';
import TextQuestion from '../../components/TextQuestion';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => createStyles({
  textCenter: {
    textAlign: 'center',
  },
  blockCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  form: {
    marginTop: '1rem',
    maxWidth: 600,
    minHeight: '20rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  formRow: {
    '& > *': {
      margin: theme.spacing(1),
      display: 'inline-block',
      veriticalAlign: 'middle',
    },
    '& label:nth-of-type(odd)': {
      backgroundColor: '#f0f0f0',
    },
  },
  flexRow: {
    display: 'flex',
    columnGap: '10px',
    alignItems: 'center',
  },
  content: {
    minHeight: 'calc(100vh - 7rem)',
  },
  instructions: {
    marginBottom: '1rem',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    '& > *:first-child': {
      marginRight: '1rem',
    },
  },
  answer: {
    minHeight: '2rem',
  }
}));

const questionTypes : { [name: string]: any } = {
  'individually': RangeQuestion,
  'interpersonally': RangeQuestion,
  'socially': RangeQuestion,
  'overall': RangeQuestion,
  'comment': TextQuestion,
};

const questions : string[] = Object.keys(questionTypes);
const questionCount = questions.length;

const OrsPage = ({ history, match }: any) => {
  const pageCount = questionCount + 1;
  const defaultData = { nextPage: 1 };
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(defaultData);
  const [instrument, setInstrument] = useState();
  const [error, setError] = useState(undefined);

  const hash = history.location.hash.substring(1);
  const token = match.params.token;
  const pageNumber = Math.min(parseInt(hash), data.nextPage) || 1;
  const question = questions[pageNumber - 1];
  const QuestionClass = questionTypes[question];
  
  useEffect(() => {
    getOrs(token).then(({instrument: instr, ...detail}) => {
      if (detail.error) {
        setError(detail.error);
      } else {
        setData({ nextPage: 1, ...detail });
        const range = [instr.low, instr.high];
        const comment = instr.comment_title;  // make this consistent with other questions.
        setInstrument({questions, range, comment, ...instr});
      }
    }).catch((e) => {
      setError(e);
    }).finally(() => {
      setLoading(false);
    });
  }, [token]);

  useEffect(() => {
    if (data !== defaultData) {
      saveOrs(token, data);
    }
  }, [token, JSON.stringify(data)]);

  const onComplete = (action: string, pageData: object) => {
    let nextPage = pageNumber;
    if (action === 'back' && pageNumber > 1) {
      nextPage -= 1;
    }
    if (action === 'next' && pageNumber < pageCount) {
      nextPage += 1;
    }
    setData({ ...data, ...pageData, ...{ nextPage: Math.max(data.nextPage, nextPage) } });
    history.push(`/ors/${token}#${nextPage}`);
  }

  return (
    <Page title={"Outcome Rating Score : Excel Psychology"}>
      <div className={classes.content}>
        <img src="/ExcelLogo143x59.png" className={classes.blockCenter} alt="Excel Logo" />
        <Typography variant="h1" className={classes.textCenter}>
          Outcome Rating Score
        </Typography>
        {!loading && error && <ErrorMessage error={error} />}
        {!loading && !error && pageNumber <= questionCount && <QuestionClass questionNumber={pageNumber} question={question} instrument={instrument} data={data} classes={classes} onComplete={onComplete} />}
        {!loading && !error && pageNumber > questionCount && <SubmitPage data={data} classes={classes} onComplete={onComplete} />}
      </div>
    </Page>
  );
};

export default OrsPage;
