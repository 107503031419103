import React from 'react';
import { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { ERROR, OK, FOCUS, CLEAR } from './StatusIcon';
import moment from 'moment';

type DateFieldProps = {
  setStatus: Function,
  onChange: Function,
}

type Event = {
  target: {
    value: string,
  }
}

const DateField = ({value, allowEmpty, setStatus, onChange, ...props} : any) => {
  const [focused, setFocused] = useState(false);

  const update = (event: Event) => {
    const dateStr = event.target.value.replace(/[^0-9/]/g, '');
    onChange(dateStr);
  };

  const validate = (dateStr: string, allowEmpty: boolean) => {
    let status;
    if (dateStr === '') {
      status = allowEmpty ? CLEAR : ERROR;
    } else {
      const date = moment(dateStr, 'D/M/YYYY', true);
      status = date.isValid() ? OK : ERROR;
    }
    return status;
  };

  useEffect(() => {
    const status = validate(value, allowEmpty);
    if (focused) {
      setStatus(status === ERROR ? FOCUS : status);
    } else {
      setStatus(status);
    }
  }, [value, allowEmpty, setStatus, focused]);

  return (
    <TextField
      value={value}
      onChange={update}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      {...props}
    />
  );
}

export default DateField;
