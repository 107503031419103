import React from 'react';
import { Route } from 'react-router-dom';
import PaymentPage from './PaymentPage';

const routes = () => {
  return [
    <Route key="/payment" exact path="/payment/:code" component={PaymentPage}></Route>,
  ]
};
export default routes;
