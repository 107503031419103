import Alert from '@material-ui/lab/Alert';
import React from 'react';
import Typography from '@material-ui/core/Typography';

type ErrorMessageProps = {
    error?: string
  };
  
  const ErrorMessage = ({error}: ErrorMessageProps) => {
    const messages: { [key: string]: string } = {
      'Invalid link': 'This link is no longer valid.  Please contact reception for assistance.',
      'Already submitted': 'You have already completed and submitted this questionnaire.',
    }
    return (
      <div>
        <Alert severity="error">{String(error)}</Alert>
        <Typography variant="body1">
          {error && messages[error]}
        </Typography>
      </div>
    );
  }

  export default ErrorMessage;