import { request, makePath } from './http';

export function getPaymentDetail(code : string) {
  return request('GET', makePath('payment', 'detail', code));
}

export function getPaymentTransaction(code : string) {
  return request('POST', makePath('payment', 'transaction', code));
}

export function receiptUrl(code: string) {
  const ref = code.split('.')[1];
  return makePath('payment', 'receipt', code, `receipt-${ref}.pdf`);
}
