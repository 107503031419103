import React, { useEffect, useState } from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import Pagination from './Pagination';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const titleCase = (str: string) => 
  str.replace(
    /\w\S*/g,
    (txt: string) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

const marks = [{value:0},{value:10},{value:20},{value:30},{value:40},{value:50},{value:60},{value:70},{value:80},{value:90},{value:100}];

const unsetStyles = makeStyles({
  root: {
    height: 8,
  },
  thumb: {
    display: 'none'
  }
});

const setStyles = makeStyles({
  root: {
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#89b1df',
    border: '2px solid currentColor',
    marginTop: -12,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
});

const RangeQuestion = ({questionNumber, question, instrument, data, onComplete, classes} : any) => {
  const progress = 100 * questionNumber / instrument.questions.length;
  const pageKey = question.toUpperCase();
  const instructions = instrument.intro;
  const title = instrument[question];
  const subtitle = instrument[question + '_more'];
  const [lowRange, highRange] = instrument.range;
  const [answer, setAnswer] = useState(data[pageKey]);
  const unset = answer === undefined;
  const sliderArgs = unset ? {classes: unsetStyles()} : {classes: setStyles()};
  console.log({unset, sliderArgs});
  const complete = (action : string) => {
    onComplete(action, {[pageKey]: answer});
  }
  useEffect(() => {
    setAnswer(data[pageKey]);
  }, [data, questionNumber]);

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <div className={classes.instructions}>
        <Typography variant="body1">
          {instructions}
        </Typography>
      </div>
      <div className={classes.title}>
        <Typography variant="h2" color="primary" className={classes.title}>
          {questionNumber}.
        </Typography>
        <Typography variant="h2" color="primary" className={classes.title}>
          {title}
        </Typography>
      </div>
      <Typography variant="h3" align="center">
        {subtitle}
      </Typography>
      <div className={classes.flexRow}>
        <Typography component="span">{lowRange}</Typography>
        <Slider
          min={0} 
          max={100}
          marks={marks}
          track={false}
          value={answer == undefined ? 99 : answer} 
          onChange={(e, v) => setAnswer(v)} 
          aria-labelledby="continuous-slider" 
          valueLabelDisplay="auto"
          {...sliderArgs}
        />
        <Typography component="span">{highRange}</Typography>
      </div>
      <Typography variant="caption" align="center">
        Click on the line to mark the position that best matches your experience.
      </Typography>
      <Pagination
        onBack={questionNumber===1 ? undefined : ()=>complete('back')}
        onNext={()=>complete('next')}
        nextDisabled={answer===undefined}
      />
      <LinearProgress variant="determinate" value={progress}/>
    </form>
  );
};

export default RangeQuestion;
