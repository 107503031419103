import React from 'react';
import { Route } from 'react-router-dom';
import HomePage from './HomePage';

const routes = () => {
  return [
    <Route key="/" exact path="/" component={HomePage}></Route>,
  ]
};
export default routes;
