import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  heading: {
    fontWeight: 600,
    textAlign: 'right',
    '&::after': {
      content: "':'",
    },
  },
  container: {
    padding: '1rem',
    marginTop: '2rem',
    marginBottom: '2rem',
  }
}));

type PaymentDetail = {
  description: string,
  reference: string,
  firstName: string,
  surname: string,
  date: string,
  practitioner: string,
  amount: string,
  rebate: string,
  status: string,
  payment: string,
  memo: string,
};

type PaymentDetailProps = {
  detail: PaymentDetail,
};

const PaymentDetail = ({detail} : PaymentDetailProps) => {
  const classes = useStyles();

  return (
    <Paper variant="outlined" className={classes.container}>
      <Grid container spacing={1}>
        <Grid className={classes.heading} item xs={4}>
          Name
        </Grid>
        <Grid item xs={8}>
          {detail.firstName} {detail.surname}
        </Grid>

        <Grid className={classes.heading} item xs={4}>
          Date
        </Grid>
        <Grid item xs={8}>
          {detail.date}
        </Grid>

        <Grid className={classes.heading} item xs={4}>
          Description
        </Grid>
        <Grid item xs={8}>
          {detail.description}
        </Grid>

        <Grid className={classes.heading} item xs={4}>
          Practitioner
        </Grid>
        <Grid item xs={8}>
          {detail.practitioner}
        </Grid>

        <Grid className={classes.heading} item xs={4}>
          Reference
        </Grid>
        <Grid item xs={8}>
          {detail.reference}
        </Grid>

        <Grid className={classes.heading} item xs={4}>
          Amount
        </Grid>
        <Grid item xs={8}>
          $ {detail.amount}
        </Grid>

        <Grid className={classes.heading} item xs={4}>
          Eligible Rebate
        </Grid>
        <Grid item xs={8}>
          $ {detail.rebate}
        </Grid>

        {detail.memo && (
          <>
            <Grid className={classes.heading} item xs={4}>
              Memo
            </Grid>
            <Grid item xs={8}>
            {detail.memo}
            </Grid>
          </>
        )}

        {detail.payment && (
          <React.Fragment>
            <Grid className={classes.heading} item xs={4}>
              Payment
            </Grid>
            <Grid item xs={8}>
              {detail.payment}
            </Grid>
          </React.Fragment>
        )}

      </Grid>
    </Paper>

  );
}

export default PaymentDetail;
