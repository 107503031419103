import React from 'react';
import { useState } from 'react';
import StatusIcon from './StatusIcon';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ValidatedField from './ValidatedField';
import Pagination from './Pagination';
import { CLEAR } from './StatusIcon';

const EmergencyContactPage = ({data, onComplete, classes} : any) => {
  const [emergencyContact, setEmergencyContact] = useState(data.emergencyContact);
  const [emergencyContactStatus, setEmergencyContactStatus] = useState(CLEAR);
  const [emergencyContactTelephone, setEmergencyContactTelephone] = useState(data.emergencyContactTelephone);
  const [emergencyContactTelephoneStatus, setEmergencyContactTelephoneStatus] = useState(CLEAR);
  const [emergencyContactRelationship, setEmergencyContactRelationship] = useState(data.emergencyContactRelationship);
  const [emergencyContactRelationshipStatus, setEmergencyContactRelationshipStatus] = useState(CLEAR);
  const [allowEmpty, setAllowEmpty] = useState(true);

  const complete = (action : string) => {
    const data = {
      emergencyContact,
      emergencyContactTelephone,
      emergencyContactRelationship,
    }
    onComplete(action, data);
  }
  // intake form is https://drive.google.com/file/d/0BxhsAjye6VcMMDVMd2pkeURKTjA/view?ths=true
  return (
    <form className={classes.form} noValidate autoComplete="off">
      <div className={classes.formRow}>
        <ValidatedField
          label="Emergency Contact"
          value={emergencyContact}
          onChange={setEmergencyContact}
          setStatus={setEmergencyContactStatus}
          allowEmpty={allowEmpty}
          InputLabelProps={{ shrink: true, }}
        />
        <StatusIcon status={emergencyContactStatus}/>
      </div>

      <div className={classes.formRow}>
        <ValidatedField
          label="Telephone"
          value={emergencyContactTelephone}
          onChange={setEmergencyContactTelephone}
          setStatus={setEmergencyContactTelephoneStatus}
          allowEmpty={allowEmpty}
          InputLabelProps={{ shrink: true, }}
        />
        <StatusIcon status={emergencyContactTelephoneStatus}/>
      </div>

      <div className={classes.formRow}>
        <ValidatedField
          label="Relationship to you"
          value={emergencyContactRelationship}
          onChange={setEmergencyContactRelationship}
          setStatus={setEmergencyContactRelationshipStatus}
          allowEmpty={allowEmpty}
          InputLabelProps={{ shrink: true, }}
        />
        <StatusIcon status={emergencyContactRelationshipStatus}/>
      </div>

      <Pagination onBack={()=>complete('back')} onNext={()=>complete('next')}/>
    </form>

  );
};

export default EmergencyContactPage;
