import React from 'react';
import { useState } from 'react';
import StatusIcon from './StatusIcon';
import EmailAddressField from './EmailAddressField';
import ValidatedField from './ValidatedField';
import Pagination from './Pagination';
import { CLEAR } from './StatusIcon';

const ContactPage = ({data, onComplete, classes} : any) => {
  const [mobile, setMobile] = useState(data.mobile);
  const [mobileStatus, setMobileStatus] = useState(CLEAR);
  const [email, setEmail] = useState(data.email);
  const [emailStatus, setEmailStatus] = useState(CLEAR);
  const [workTelephone, setWorkTelephone] = useState(data.workTelephone);
  const [workTelephoneStatus, setWorkTelephoneStatus] = useState(CLEAR);
  const [homeTelephone, setHomeTelephone] = useState(data.homeTelephone);
  const [homeTelephoneStatus, setHomeTelephoneStatus] = useState(CLEAR);
  const [allowEmpty, setAllowEmpty] = useState(true);

  const complete = (action : string) => {
    const data = {
      mobile,
      email,
      homeTelephone,
      workTelephone,
    }
    onComplete(action, data);
  }
  // intake form is https://drive.google.com/file/d/0BxhsAjye6VcMMDVMd2pkeURKTjA/view?ths=true
  return (
    <form className={classes.form} noValidate autoComplete="off">

      <div className={classes.formRow}>
        <ValidatedField
          label="Mobile"
          value={mobile}
          onChange={setMobile}
          setStatus={setMobileStatus}
          allowEmpty={allowEmpty}
          InputLabelProps={{ shrink: true, }}
        />
        <StatusIcon status={mobileStatus}/>
      </div>
      <div className={classes.formRow}>
      <EmailAddressField
        label="Email"
        value={email}
        onChange={setEmail}
        setStatus={setEmailStatus}
        allowEmpty={allowEmpty}
        InputLabelProps={{ shrink: true, }}
      />
        <StatusIcon status={emailStatus}/>
      </div>
      <div className={classes.formRow}>
        <ValidatedField
          label="Home Telephone"
          value={homeTelephone}
          onChange={setHomeTelephone}
          setStatus={setHomeTelephoneStatus}
          allowEmpty={true}
          InputLabelProps={{ shrink: true, }}
        />
        <StatusIcon status={homeTelephoneStatus}/>
      </div>
      <div className={classes.formRow}>
        <ValidatedField
          label="Work Telephone"
          value={workTelephone}
          onChange={setWorkTelephone}
          setStatus={setWorkTelephoneStatus}
          allowEmpty={true}
          InputLabelProps={{ shrink: true, }}
        />
        <StatusIcon status={workTelephoneStatus}/>
      </div>
      <Pagination onBack={()=>complete('back')} onNext={()=>complete('next')}/>
    </form>

  );
};

export default ContactPage;
