import React from 'react';
import { useState, useEffect } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import SubmitIcon from '@material-ui/icons/KeyboardArrowRight';


const useStyles = makeStyles((theme: Theme) => createStyles({
  form: {
    border: 'solid black 1px',
    padding: '1rem 1rem 1rem 1rem',
    marginBottom: '5rem',
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  indent: {
    marginLeft: '3rem',
    marginBottom: '2rem',
  },
  buttonRow: {
    padding: '1rem',
    textAlign: 'center',
  },
  spinner: {
    color: '#ffffff',
    marginLeft: '0.6rem',
  },
}));

const ConsentForm = ({data, setData, busy, onSubmit} : any) => {
  const classes = useStyles();
  const { consented, submitted, contactName, contactPhone } = data;

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <div className={classes.indent}>
        <p>
          Emergency contact details
        </p>
        <div>
          <TextField
            value={contactName}
            onChange={(event) => !submitted && setData({...data, contactName: event.target.value})}
            label="Contact Name"
          />
        </div>
        <div>
          <TextField
            value={contactPhone}
            onChange={(event) => !submitted && setData({...data, contactPhone: event.target.value})}
            label="Contact Phone"
          />
        </div>
      </div>
      <div className={classes.row}>
        <Checkbox
          color="primary"
          checked={consented}
          onChange={(event) => !submitted && setData({...data, consented: event.target.checked})}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <div>
          I have read and understood the information in this Consent Form and have discussed any outstanding questions with the practice/psychologist. I agree to the above conditions for telehealth psychological services to be provided by Excel Psychology.
        </div>
      </div>
      <div className={classes.buttonRow}>
        {submitted ?
          <Alert severity="success">Successfully submitted</Alert>
        :
          <Button variant="contained" color="primary" disabled={!consented || !contactName || !contactPhone} onClick={() => !submitted && onSubmit({...data, submitted: true})}>
            Submit
            {busy && <CircularProgress size={15} thickness={8} className={classes.spinner}/>}
            {!busy && <SubmitIcon/>}
          </Button>
        }
      </div>
    </form>
  );
};

export default ConsentForm;
