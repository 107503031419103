import { createMuiTheme } from '@material-ui/core/styles';

// https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=264f73&secondary.color=b1ff3c
const primaryColor = '#1565c0';

const secondaryColor = '#c2185b';

const textPrimary = '#545456';
const textSecondary = '#264f73';

const theme = createMuiTheme({

  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },
    background: {
      'default': '#fff',
    }
  },
  typography: {
    'fontFamily': '"Roboto", "Helvetica", "Arial", sans-serif',

    h1: {
      fontSize: 24,
      lineHeight: 1.18,
      fontWeight: 700,
    },
    h2: {
      fontSize: 20,
      lineHeight: 1.25,
      fontWeight: 400,
    },
    h3: {
      fontSize: 18,
      lineHeight: 1.33,
      fontWeight: 300,
    },
    body2: {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
  },
});

export default theme;
