import { makePath, request } from './http';

export function getDass21(token: string) {
  return request('GET', makePath('surveyform', 'getDass21', token));
}

export function saveDass21(token: string, data: object) {
  return request('POST', makePath('surveyform', 'saveDass21', token), {body: data});
}

export function getK10Plus(token: string) {
  return request('GET', makePath('surveyform', 'getK10Plus', token));
}

export function saveK10Plus(token: string, data: object) {
  return request('POST', makePath('surveyform', 'saveK10Plus', token), {body: data});
}

export function getOrs(token: string) {
  return request('GET', makePath('surveyform', 'getOrs', token));
}

export function saveOrs(token: string, data: object) {
  return request('POST', makePath('surveyform', 'saveOrs', token), {body: data});
}

export function getSrs(token: string) {
  return request('GET', makePath('surveyform', 'getSrs', token));
}

export function saveSrs(token: string, data: object) {
  return request('POST', makePath('surveyform', 'saveSrs', token), {body: data});
}

export function getConsent(token: string) {
  return request('GET', makePath('consentform', 'getConsent', token));
}

export function saveConsent(token: string, data: object) {
  return request('POST', makePath('consentform', 'saveConsent', token), {body: data});
}
