import React from 'react';
import { useState } from 'react';
import StatusIcon from './StatusIcon';
import DateField from './DateField';
import ValidatedField from './ValidatedField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Pagination from './Pagination';
import { OK, CLEAR } from './StatusIcon';

const NamePage = ({data, onComplete, classes} : any) => {
  const [firstName, setFirstName] = useState(data.firstName);
  const [firstNameStatus, setFirstNameStatus] = useState(CLEAR);
  const [surname, setSurname] = useState(data.surname);
  const [surnameStatus, setSurnameStatus] = useState(CLEAR);
  const [dateOfBirth, setDateOfBirth] = useState(data.dateOfBirth);
  const [dateOfBirthStatus, setDateOfBirthStatus] = useState(CLEAR);
  const [gender, setGender] = useState(data.gender);
  const [allowEmpty, setAllowEmpty] = useState(true);

  const complete = (action : string) => {
    if (action === 'next') {
      if (dateOfBirthStatus !== OK ||
          firstNameStatus !== OK ||
          surnameStatus !== OK) {
        console.log({dateOfBirthStatus, firstNameStatus, surnameStatus});
        setAllowEmpty(false);
        return;
      }
    }

    const data = {
      dateOfBirth,
      firstName,
      surname,
      gender,
    }
    onComplete(action, data);
  }
  // intake form is https://drive.google.com/file/d/0BxhsAjye6VcMMDVMd2pkeURKTjA/view?ths=true
  return (
    <form className={classes.form} noValidate autoComplete="off">
      <div className={classes.formRow}>
        <ValidatedField
          label="First Name"
          value={firstName}
          onChange={setFirstName}
          setStatus={setFirstNameStatus}
          allowEmpty={allowEmpty}
          InputLabelProps={{ shrink: true, }}
        />
        <StatusIcon status={firstNameStatus}/>
      </div>
      <div className={classes.formRow}>
        <ValidatedField
          label="Last Name"
          value={surname}
          onChange={setSurname}
          setStatus={setSurnameStatus}
          allowEmpty={allowEmpty}
          InputLabelProps={{ shrink: true, }}
        />
        <StatusIcon status={surnameStatus}/>
      </div>
      <div className={classes.formRow}>
        <DateField
          allowEmpty={allowEmpty}
          value={dateOfBirth}
          onChange={setDateOfBirth}
          setStatus={setDateOfBirthStatus}
          label="Date of Birth"
          placeholder="DD/MM/YYYY"
          InputLabelProps={{ shrink: true, }}
        />
        <StatusIcon status={dateOfBirthStatus}/>
      </div>

      <div className={classes.formRow}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Gender</FormLabel>
          <RadioGroup aria-label="gender" name="gender1" value={gender} onChange={e => setGender(e.target.value)}>
            <FormControlLabel value="female" control={<Radio />} label="Female" />
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel value="other" control={<Radio />} label="Other / prefer not to say" />
          </RadioGroup>
        </FormControl>
      </div>

      <Pagination onNext={()=>complete('next')}/>
    </form>

  );
};

export default NamePage;
