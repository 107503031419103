import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { getPaymentDetail, getPaymentTransaction, receiptUrl } from '../../api/payment';
import Page from '../../components/Page';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PaymentDetail from './components/PaymentDetail';
import Container from '@material-ui/core/Container';
import { withStyles, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { RouteComponentProps } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Footer from './components/Footer';
import { loadStripe } from '@stripe/stripe-js';
import config from '../../config';

// success http://lvh.me:3000/payment/PquiPZMWVU2squEXqarS.1472-23391/success?session_id=cs_test_hfPPu1OaktygVu0FCmbIZ6dVB8pC9lzdL0oaLwcGrzPCieid9gSlHDMo

const useStyles = makeStyles((theme: Theme) => createStyles({
  textCenter: {
    textAlign: 'center',
  },
  blockCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  form: {
    marginTop: '1rem',
    width: 'max-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  formRow: {
    '& > *': {
      margin: theme.spacing(1),
      display: 'inline-block',
      veriticalAlign: 'middle',
    },
  },
  content: {
    minHeight: 'calc(100vh - 7rem)',
  },
  footer: {
    height: '5rem',
  },
}));

const WhiteCircularProgress = withStyles({
  root: {
    color: '#FFFFFF',
  },
})(CircularProgress);


type PaymentPageProps = {
  code: string,
};

const stripePromise = loadStripe(config.stripe.apiKey!);

const Spinner = () => {
  const classes = useStyles();
  return (
    <CircularProgress color="secondary" className={classes.blockCenter}/>
  );
};

const Form = ({code, detail, onPayNow, onDownloadReceipt, loading, paying}: any) => {
  const classes = useStyles();
  return (
    <Container>
      <PaymentDetail detail={detail}/>
      {detail.status === 'unpaid' &&
        <form className={classes.form} noValidate autoComplete="off">
          {paying ?
            <Button variant="contained" color="secondary">
              Wait...&nbsp;
              <WhiteCircularProgress className={classes.blockCenter} size={15} thickness={6}/>
            </Button>
            :
            <Button variant="contained" color="secondary" disabled={false} onClick={onPayNow}>
              Pay Now
            </Button>
          }
        </form>
      }
      {detail.status === 'paid' && (
       <React.Fragment>
         <Alert severity="success">
          Payment received
         </Alert>
         <Button variant="contained" onClick={onDownloadReceipt}>Download Receipt</Button>
       </React.Fragment>
     )}
    </Container>
  );
}

const ErrorMessage = ({error}: any) => {
  return (
    <Alert severity="error">{String(error)}</Alert>
  );
}

const PaymentPage = ({match, location}: RouteComponentProps<PaymentPageProps>) => {
  const params = new URLSearchParams(location.search.substring(1));
  const sessionId = params.get('session_id');
  console.log({sessionId});
  const classes = useStyles();
  const code = match.params.code;
  const [loading, setLoading] = useState(true);
  const [detail, setDetail] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [paying, setPaying] = useState()

  useEffect(() => {
    getPaymentDetail(code).then((detail) => {
      if (detail.error) {
        setError(detail.error);
      } else {
        setDetail(detail);
      }
    }).catch((e) => {
      setError(e);
    }).finally(() => {
      setLoading(false);
    });
  }, [code]);

  const onPayNow = async () => {
    setPaying(true);
    const transaction = await getPaymentTransaction(code);
    const stripe = await stripePromise;
    const { error } = await stripe!.redirectToCheckout({
      sessionId: transaction.sessionId,
    });
    console.log(error);
  };

  const onDownloadReceipt = () => {
    const link = receiptUrl(code);
    window.open(link, "_blank");
  }

  return (
    <Page title={"Payment Gateway : Excel Psychology"}>
      <div className={classes.content}>
        <img src="/ExcelLogo143x59.png" className={classes.blockCenter} alt="Excel Logo"/>
        <Typography variant="h1" className={classes.textCenter}>
          Secure Payment Gateway
        </Typography>
        {error ? <ErrorMessage error={error}/> : loading ? <Spinner/> : <Form code={code} detail={detail} loading={loading} paying={paying} onPayNow={onPayNow} onDownloadReceipt={onDownloadReceipt}/>}
      </div>
      <Footer/>
    </Page>
  );
};

export default PaymentPage;
