import React from 'react';
import { useState } from 'react';
import StatusIcon from './StatusIcon';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ValidatedField from './ValidatedField';
import Pagination from './Pagination';
import { CLEAR } from './StatusIcon';

const HistoryPage = ({data, onComplete, classes} : any) => {
  const [seenPsychologist, setSeenPsychologist] = useState(data.seenPsychologist);
  const [seenPsychologistWhen, setSeenPsychologistWhen] = useState(data.seenPsychologistWhen);
  const [attemptedSuicide, setAttemptedSuicide] = useState(data.attemptedSuicide);
  const [attemptedSuicideWhen, setAttemptedSuicideWhen] = useState(data.attemptedSuicideWhen);
  const [usingSubstances, setUsingSubstances] = useState(data.usingSubstances);
  const [usedSubstances, setUsedSubstances] = useState(data.usedSubstances);
  const [usedSubstancesWhen, setUsedSubstancesWhen] = useState(data.usedSubstancesWhen);

  const complete = (action : string) => {
    const data = {
      seenPsychologist,
      seenPsychologistWhen,
      attemptedSuicide,
      attemptedSuicideWhen,
      usingSubstances,
      usedSubstances,
      usedSubstancesWhen,
    };
    onComplete(action, data);
  };
  // intake form is https://drive.google.com/file/d/0BxhsAjye6VcMMDVMd2pkeURKTjA/view?ths=true
  return (
    <form className={classes.form} noValidate autoComplete="off">
      <div className={classes.formRow}>
        <FormControlLabel
          control={<Checkbox checked={seenPsychologist} onChange={(e)=>setSeenPsychologist(e.target.checked)} name="seenPsychologist" />}
          label="I have seen a psychologist before"
        />
      </div>

      <div className={classes.formRow}>
        <ValidatedField
          className={classes.inset}
          label="if so, when?"
          value={seenPsychologistWhen}
          onChange={setSeenPsychologistWhen}
          allowEmpty={true}
          InputLabelProps={{ shrink: true, }}
        />
      </div>

      <div className={classes.formRow}>
        <FormControlLabel
          control={<Checkbox checked={attemptedSuicide} onChange={(e)=>setAttemptedSuicide(e.target.checked)} name="attemptedSuicide" />}
          label="I have attempted suicide."
        />
      </div>

      <div className={classes.formRow}>
        <ValidatedField
          className={classes.inset}
          label="if so, when?"
          value={attemptedSuicideWhen}
          onChange={setAttemptedSuicideWhen}
          allowEmpty={true}
          InputLabelProps={{ shrink: true, }}
        />
      </div>

      <div className={classes.formRow}>
        <FormControlLabel
          control={<Checkbox checked={usingSubstances} onChange={(e)=>setUsingSubstances(e.target.checked)} name="usingSubstances" />}
          label="I am currently using drugs or alcohol."
        />
      </div>

      <div className={classes.formRow}>
        <FormControlLabel
          control={<Checkbox checked={usedSubstances} onChange={(e)=>setUsedSubstances(e.target.checked)} name="usedSubstances" />}
          label="I have used drugs or alcohol."
        />
      </div>

      <div className={classes.formRow}>
        <ValidatedField
          className={classes.inset}
          label="if so, when?"
          value={usedSubstancesWhen}
          onChange={setUsedSubstancesWhen}
          allowEmpty={true}
          InputLabelProps={{ shrink: true, }}
        />
      </div>

      <Pagination onBack={()=>complete('back')} onNext={()=>complete('next')}/>
    </form>

  );
};

export default HistoryPage;
