import DassPage from './DassPage';
import React from 'react';
import { Route } from 'react-router-dom';

const routes = () => {
  return [
    <Route key="/dass" exact path="/dass/:token" component={DassPage}></Route>, /* deprecated */
    <Route key="/dass21" exact path="/dass21/:token" component={DassPage}></Route>,
  ]
};
export default routes;
