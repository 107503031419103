import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  page: {
    width: '100%',
    display: 'flex',
    paddingTop: '2rem',
    justifyContent: 'center',
  },
  content: {
    maxWidth: 800,
    margin: '1rem',
  },
});

type PageProps = {
  title: string,
  children: React.ReactNode,
}

const Page = ({title, children} : PageProps) => {
  const classes = useStyles();

  useEffect(() => {
    if (title) document.title = title;
  }, [title]);

  return (
    <div className={classes.page}>
      <div className={classes.content}>
        {children}
      </div>
    </div>
  );
};

export default Page;
