import { useEffect, useState } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Pagination from '../../../components/Pagination';
import { Prompt } from 'react-router-dom';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const answers = [
  'None of the time',
  'A little of the time',
  'Some of the time',
  'Most of the time',
  'All of the time',
];

interface ClassProp {
  instructions: string,
  title: string,
  formRow: string,
  answer: string,
  form: string,
};

type K10QuestionProps = {
  questionNumber: number,
  question: JSX.Element,
  data: any,
  classes: ClassProp,
  answer: string,
  setAnswer: CallableFunction,
  prompt?: string,
  showInstructions?: boolean,
};

const K10Question = ({questionNumber, question, data, classes, answer, setAnswer, showInstructions = true}: K10QuestionProps) => {
  return (
    <>
     <div className={classes.instructions}>
        {showInstructions && 
          <Typography variant="body1">
            Please read the following statement and select the answer which indicates how much the statement
            applied to you <b>over the past week</b>. There are no right or wrong answers. Do not spend too much time
            on any statement.
          </Typography>
        }
      </div>
      <div className={classes.title}>
        <Typography variant="h2" color="primary" className={classes.title}>
          {questionNumber}.
        </Typography>
        <Typography variant="h2" color="primary" className={classes.title}>
          {question}
        </Typography>
      </div>
      <div className={classes.formRow}>
        <FormControl component="fieldset">
          <RadioGroup aria-label="answer" value={parseInt(answer)} onChange={(e)=>setAnswer(e.target.value)}>
            {answers.map((q, i) =>
              <FormControlLabel key={i} value={i+1} control={<Radio />} label={q} className={classes.answer}/>
            )}
          </RadioGroup>
        </FormControl>
      </div>
    </>
  )
};

const PlusQuestion = ({questionNumber, question, data, classes, answer, setAnswer, prompt}: K10QuestionProps) => {
  return (
    <>
     <div className={classes.instructions}>
        <Typography variant="body1">
          {Prompt}
        </Typography>
      </div>
      <div className={classes.title}>
        <Typography variant="h2" color="primary" className={classes.title}>
          {questionNumber}.
        </Typography>
        <Typography variant="h2" color="primary" className={classes.title}>
          {question}
        </Typography>
      </div>
      <div className={classes.formRow}>
        <FormControl fullWidth>
          <TextField
            fullWidth
            id="standard-helperText"
            label={prompt}
            value={answer}
            variant="filled"
            onChange={(e)=>setAnswer(e.target.value.replace(/\D/g,''))}
          />
        </FormControl>
      </div>
    </>
  )
};

const questions = [
  {
    type: K10Question,
    question: <>How often did you feel tired out for no good reason?</>
  },
  {
    type: K10Question,
    question: <>How often did you feel nervous?</>
  },
  {
    type: K10Question,
    question: <>How often did you feel so nervous that nothing could calm you down?</>
  },
  {
    type: K10Question,
    question: <>How often did you feel hopeless?</>
  },
  {
    type: K10Question,
    question: <>How often did you feel restless or fidgety?</>
  },
  {
    type: K10Question,
    question: <>How often did you feel so restless you could not sit still?</>
  },
  {
    type: K10Question,
    question: <>How often did you feel depressed?</>
  },
  {
    type: K10Question,
    question: <>How often did you feel that everything was an effort?</>
  },
  {
    type: K10Question,
    question: <>How often did you feel so sad that nothing could cheer you up?</>
  },
  {
    type: K10Question,
    question: <>How often did you feel worthless?</>
  },
  {
    type: PlusQuestion,
    question: <span>In the last four (4) weeks, how many days were you <b>TOTALLY UNABLE</b> to work or study or manage you day to day activities because of these feelings?</span>,
    prompt: 'Number of days'
  },
  {
    type: PlusQuestion,
    question: <span>Aside from those days, in the last four weeks, how many days were able to work or study or manage your day to day activities, but had to <b>CUT DOWN</b> on what you were doing because of these feelings?</span>,
    prompt: 'Number of days'
  },
  {
    type: PlusQuestion, 
    question: <>In the past four weeks, how many times have you seen a doctor or any other health professional about these feelings?</>,
    prompt: 'Number of consultations?'
  },
  {
    type: K10Question,
    question: <>In the last four weeks, how often have physical health problems been the main cause of these feelings?</>,
    showInstructions: false,
  }
]

type K10PlusQuestionProps = {
  questionNumber: number,
  data: any,
  onComplete: CallableFunction,
  classes: ClassProp,
};

const K10PlusQuestion = ({questionNumber, data, onComplete, classes} : K10PlusQuestionProps) => {
  const [answer, setAnswer] = useState('');
  const {type: Question, ...args} = questions[questionNumber-1];
  const progress = 100 * questionNumber / questions.length;
  const complete = (action : string) => {
    onComplete(action, {[questionNumber]: answer});
  }
  useEffect(() => {
    setAnswer(data[questionNumber] || '')
  }, [data, questionNumber]);

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <Question {...args} 
        questionNumber={questionNumber}
        classes={classes}
        data={data}
        answer={answer}
        setAnswer={setAnswer}
      />
      <Pagination
        onBack={questionNumber===1 ? undefined : ()=>complete('back')}
        onNext={()=>complete('next')}
        nextDisabled={answer===''}
      />
      <LinearProgress variant="determinate" value={progress}/>
    </form>
  );
};

export default K10PlusQuestion;
