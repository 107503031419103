
import React from 'react';
import Typography from '@material-ui/core/Typography';

const TeleheathConsent = ({classes}: any) => {
  return (
    <div>
      <Typography variant="h1" className={classes.textCenter}>
        Telehealth Client Informed Consent Form
      </Typography>
      <Typography variant="body1" component="div">
        <p>
          In addition to our general “Confidentiality and Consent Form”, which includes information about confidentiality and its limits, information storage, informed consent to treatment, and consent to exchange information, we ask that you read and sign this specific Telehealth Client Informed Consent Form.
        </p>
        <p>
          If you would like a copy of the original, general Confidentiality and Consent Form, please let us know.
        </p>
        <p>
          As part of providing a psychological service to you, Excel Psychology​ ​needs to collect and record personal information from you that is relevant to your situation, such as your name, contact information, medical history and other relevant information as part of providing psychological services to you. This collection of personal information will be a necessary part of the psychological assessment and treatment that is conducted.
        </p>
        <p>
          A detailed description of how your personal information is managed, how you can access your personal information, and how to lodge any concerns or complaints about this service or how your personal information is managed must be provided to you by your health professional on request.
        </p>
        <h3>Disclosure of personal information</h3>
        <p>
          Personal information gathered as part of this service will remain confidential except when:
        </p>
        <ol>
          <li> it is subpoenaed by a court; or </li>
          <li> failure to disclose the information would place you or another person at serious risk to life, health or safety; or </li>
          <li> your prior approval has been obtained to
            <ol type="a">
              <li> provide a written report to another professional or agency. e.g., a GP or a lawyer; or </li>
              <li> discuss the material with another person, eg. a parent, employer or health provider; or </li>
              <li> disclose the information in another way; or </li>
            </ol>
          </li>
          <li> you would reasonably expect your personal information to be disclosed to another professional or agency (e.g. your GP) and disclosure of your personal information to that third party is for a purpose which is directly related to the primary purpose for which your personal information was collected; or </li>
          <li> disclosure is otherwise required or authorised by law. </li>
        </ol>
        <h3> Provision of a telehealth service </h3>
        <p>
          Where appropriate the service may be provided by telephone or videoconferencing. You are responsible for the costs associated with setting up the technology needed so you can access telehealth services. Excel Psychology will be responsible for the cost of the call to you and the cost associated with the platform used to conduct telehealth services.
          To access telehealth consultations you will need access to a quiet, private space; and the appropriate device, i.e. smartphone, laptop, iPad, computer, with a camera, microphone and speakers; and a reliable broadband internet connection.
          The privacy of any form of communication via the internet is potentially vulnerable and limited by the security of the technology used. To support the security of your personal information this practice uses Doxy.Me which is compliant with the Australian standards for online security and encryption.
        </p>
        <h3> Limitations of telehealth </h3>
        <p>
          A telehealth consultation may be subject to limitations such as an unstable network connection which may affect the quality of the psychology session. In addition, there may be some services for which telehealth is not appropriate or effective. Your psychologist will consider and discuss with you the appropriateness of ongoing telehealth sessions.
        </p>
        <h3> Fees </h3>
        <p>
          The cost of a consultation (​approximately 50 minutes) is $210 with a Clinical Psychologist or $170 with a Psychologist​, which is payable at the end of the session by ​credit card or direct deposit. ​Your psychologist will discuss with you your eligibility for Medicare or other compensable funding. Bulk billing is available under certain circumstances.
        </p>
        <h3> Cancellation Policy </h3>
        <p>
          If you need to cancel or postpone your appointment, please give the psychologist at least 24 hours notice, otherwise you may be charged a cancellation fee of $50.
        </p>
      </Typography>
    </div>
  );
}

export default TeleheathConsent;
