import React from 'react';
import { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Pagination from './Pagination';

import { postIntake } from '../../../api/intake';

import { CLEAR } from './StatusIcon';

const ContactPage = ({data, onComplete, classes} : any) => {
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = () => {
    postIntake(data);
    setSubmitted(true);
  }

  const submitForm = () => {
    return (
      <form className={classes.form} noValidate autoComplete="off">
        <div className={classes.textCenter}>
          <Typography variant="body2">
            Thank for completing the intake questionnaire.
          </Typography>
          <Typography variant="body2">
            To send your questionnare to Excel, please press the Submit button.
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>
        <Pagination onBack={()=>onComplete('back', {})}/>

      </form>

    );
  }

  const confirmation = () => {
    return (
      <form className={classes.form} noValidate autoComplete="off">
        <div className={classes.textCenter}>
          <Typography variant="body2">
            Thank you!
          </Typography>
          <Typography variant="body2">
            Your intake form has been submitted.
          </Typography>
        </div>
      </form>
    );
  }

  return submitted ? confirmation() : submitForm();
};

export default ContactPage;
