import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles((theme: Theme) => createStyles({
  content: {
    marginTop: '2rem',
    marginBottom: '2rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    margin: theme.spacing(1),
  },
}));
const Pagination = ({onBack, onNext, nextDisabled} : any) => {
  const classes = useStyles();

  // intake form is https://drive.google.com/file/d/0BxhsAjye6VcMMDVMd2pkeURKTjA/view?ths=true
  return (
    <div className={classes.content}>
      {onBack ?
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<ArrowLeftIcon/>}
          onClick={onBack}
        >
          Back
        </Button>
       : <span/>}
      {onNext ?
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<ArrowRightIcon/>}
          onClick={onNext}
          disabled={nextDisabled}
        >
          Next
        </Button>
      : <span/>}
    </div>
  );
};

export default Pagination;
