import React from 'react';
import { useState } from 'react';
import Page from '../../components/Page';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import NamePage from './components/NamePage';
import CardsPage from './components/CardsPage';
import AddressPage from './components/AddressPage';
import ContactPage from './components/ContactPage';
import EmployerPage from './components/EmployerPage';
import EmergencyContactPage from './components/EmergencyContactPage';
import HistoryPage from './components/HistoryPage';
import SubmitPage from './components/SubmitPage';

const useStyles = makeStyles((theme: Theme) => createStyles({
  textCenter: {
    textAlign: 'center',
  },
  blockCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  form: {
    marginTop: '1rem',
    width: 'max-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  formRow: {
    '& > *': {
      margin: theme.spacing(1),
      display: 'inline-block',
      veriticalAlign: 'middle',
    },
  },
  content: {
    minHeight: 'calc(100vh - 7rem)',
  },
  inset: {
    marginLeft: '4rem',
  },

}));
const IntakePage = () => {

  const pages = [
    NamePage,
    AddressPage,
    CardsPage,
    ContactPage,
    EmployerPage,
    EmergencyContactPage,
    HistoryPage,
    SubmitPage,
  ];

  const initialData = {
    firstName: '',
    surname: '',
    dateOfBirth: '',
    gender: '',
    medicareNumber: '',
    medicareIrn: '',
    medicareExpiryDate: '',
    noMedicare: false,
    address: '',
    suburb: '',
    postCode: '',
    mobile: '',
    homeTelephone: '',
    workTelephone: '',
    email: '',
    occupation: '',
    employmentType: '',
    employer: '',
    emergencyContact: '',
    emergencyContactTelephone: '',
    emergencyContactRelationship: '',
    seenPsychologist: false,
    seenPsychologistWhen: '',
    attemptedSuicide: false,
    attemptedSuicideWhen: '',
    usingSubstances: false,
    usedSubstances: false,
    usedSubstancesWhen: '',
  }
  const [data, setData] = useState(initialData);
  const [pageNumber, setPageNumber] = useState(1);
  const PageContent = pages[pageNumber-1];

  const classes = useStyles();

  const onComplete = (action: string, pageData: object) => {
    const newData = {...data, ...pageData};
    setData(newData);
    if (action === 'back' && pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
    if (action === 'next' && pageNumber < pages.length) {
      setPageNumber(pageNumber + 1);
    }
  }

  // intake form is https://drive.google.com/file/d/0BxhsAjye6VcMMDVMd2pkeURKTjA/view?ths=true
  return (
    <Page title={"Confidential Intake Form : Excel Psychology"}>
      <div className={classes.content}>
        <img src="/ExcelLogo143x59.png" className={classes.blockCenter} alt="Excel Logo"/>
        <Typography variant="h1" className={classes.textCenter}>
          Confidential Intake Form
        </Typography>

        <PageContent classes={classes} onComplete={onComplete} data={data}/>

      </div>
    </Page>
  );
};

export default IntakePage;
