import K10Page from './K10Page';
import React from 'react';
import { Route } from 'react-router-dom';

const routes = () => {
  return [
    <Route key="/k10plus" exact path="/k10plus/:token" component={K10Page}></Route>,
  ]
};
export default routes;
