import React from 'react';
import { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { ERROR, OK, CLEAR, FOCUS } from './StatusIcon';

type DateFieldProps = {
  setStatus: Function,
  onChange: Function,
}

type Event = {
  target: {
    value: string,
  }
}

const weights = [1, 3, 7, 9, 1, 3, 7, 9];

const MedicareNumberField = ({value, allowEmpty, setStatus, onChange, ...props} : any) => {
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    const status = validate(value, allowEmpty);
    if (focused) {
      setStatus(status === ERROR ? FOCUS : status);  // if focused, no error
    } else {
      setStatus(status);
    }
  }, [value, allowEmpty, setStatus, focused]);

  const formatMedicareNumber = (medicareNo : string) => {
    const stripped = medicareNo.replace(/[^0-9]/g, '');
    let format = stripped.substring(0, 4);
    if (stripped.length > 4) {
      format += ' ' + stripped.substring(4, 9);
      if (stripped.length > 9) {
        format += ' ' + stripped.substring(9);
      }
    }
    return format;
  };

  const update = (event: Event) => {
    onChange(formatMedicareNumber(event.target.value));
  };

  const validate = (medicareNo: string, allowEmpty: boolean) => {
    const stripped = medicareNo.replace(/[^0-9]/g, '');
    let status;
    let valid = false;
    // Check for 11 digits
    if (stripped === '') {
      status = allowEmpty ? CLEAR : ERROR;
    } else if (stripped.length === 10) {
      // Test leading digit and checksum
      const matches = stripped.match(/^([2-6]\d{7})(\d)(\d)/);
      if (matches) {
        const base = matches[1];
        const checkDigit = parseInt(matches[2]);
        const sum = weights.reduce((sum, weight, index) => sum + weight * parseInt(base[index]), 0);
        valid = (sum % 10) === checkDigit;
        console.error("Expected check digit " + (sum % 10) + ", got " + checkDigit);
      }
      status = valid ? OK : ERROR;
    } else {
      console.error("Expected 10 digits, got " + stripped.length);
      status = ERROR;
    }
    return status;
  };

  return (
    <TextField
      value={value}
      onChange={update}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      {...props}
    />
  );
}

export default MedicareNumberField;
