import React from 'react';
import { useState } from 'react';
import StatusIcon from './StatusIcon';
import MedicareNumberField from './MedicareNumberField';
import ValidatedField from './ValidatedField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Pagination from './Pagination';
import { OK, CLEAR } from './StatusIcon';

const CardsPage = ({data, onComplete, classes} : any) => {
  const [medicareNumber, setMedicareNumber] = useState(data.medicareNumber);
  const [medicareNumberStatus, setMedicareNumberStatus] = useState(CLEAR);
  const [medicareIrn, setMedicareIrn] = useState(data.medicareIrn);
  const [medicareIrnStatus, setMedicareIrnStatus] = useState(CLEAR);
  const [medicareExpiryDate, setMedicareExpiryDate] = useState(data.medicareExpiryDate);
  const [medicareExpiryDateStatus, setMedicareExpiryDateStatus] = useState(CLEAR);
  const [noMedicare, setNoMedicare] = useState(data.noMedicare);
  const [allowEmpty, setAllowEmpty] = useState(true);

  const complete = (action : string) => {
    if (action === 'next') {
      if (!noMedicare) {
        if (medicareNumberStatus !== OK ||
            medicareExpiryDateStatus !== OK ||
            medicareIrnStatus !== OK) {
          setAllowEmpty(false);
          return;
        }
      }
    }

    const data = {
      medicareNumber,
      medicareExpiryDate,
      medicareIrn,
      noMedicare,
    }
    onComplete(action, data);
  }
  // intake form is https://drive.google.com/file/d/0BxhsAjye6VcMMDVMd2pkeURKTjA/view?ths=true
  return (
    <form className={classes.form} noValidate autoComplete="off">
      <div className={classes.formRow}>
        <MedicareNumberField
          allowEmpty={allowEmpty}
          value={medicareNumber}
          onChange={setMedicareNumber}
          setStatus={setMedicareNumberStatus}
          label="Medicare Number"
          placeholder="nnnn nnnnnn n"
          InputLabelProps={{ shrink: true, }}
        />
        <StatusIcon status={medicareNumberStatus}/>
      </div>
      <div className={classes.formRow}>
        <ValidatedField
          allowEmpty={allowEmpty}
          pattern={/^[0-9]+$/}
          filter={/[^0-9]/g}
          value={medicareIrn}
          onChange={setMedicareIrn}
          setStatus={setMedicareIrnStatus}
          label="Your IRN (Individual Reference Number)"
          placeholder="n"
          InputLabelProps={{ shrink: true, }}
        />
        <StatusIcon status={medicareIrnStatus}/>
      </div>
      <div className={classes.formRow}>
        <ValidatedField
          allowEmpty={allowEmpty}
          pattern={/^[0-9]{2}\/[0-9]{2}$/}
          filter={/[^0-9/]/g}
          value={medicareExpiryDate}
          onChange={setMedicareExpiryDate}
          setStatus={setMedicareExpiryDateStatus}
          label="Card Expiry"
          placeholder="mm/yy"
          InputLabelProps={{ shrink: true, }}
        />
        <StatusIcon status={medicareExpiryDateStatus}/>
      </div>
      <div className={classes.blockCenter}>
        <img src="/img/intake/medicare-card-303x189.png" width="303" height="189" alt="medicare card"/>
      </div>

      <div className={classes.formRow}>
        <FormControlLabel
          control={<Checkbox checked={noMedicare} onChange={(e)=>setNoMedicare(e.target.checked)} name="noMedicare" />}
          label="Skip, I will not claim a Medicare rebate."
        />
      </div>
      <Pagination onBack={()=>complete('back')} onNext={()=>complete('next')}/>
    </form>

  );
};

export default CardsPage;
