import React from 'react';
import { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { ERROR, OK, FOCUS, CLEAR } from './StatusIcon';

type DateFieldProps = {
  setStatus: Function,
  onChange: Function,
}

type Event = {
  target: {
    value: string,
  }
}

const ValidatedField = ({value, pattern, filter, allowEmpty, setStatus, onChange, ...props} : any) => {
  const [focused, setFocused] = useState(false);

  const update = (event: Event) => {
    let str = event.target.value;
    if (filter) {
      str = str.replace(filter, '');
    }
    onChange(str);
  };

  const validate = (value: string, allowEmpty: boolean) => {
    let status;
    if (value === '') {
      status = allowEmpty ? CLEAR : ERROR;
    } else {
      if (pattern) {
        status = value.match(pattern) ? OK: ERROR;
      } else {
        status = OK;
      }
    }
    return status;
  };

  useEffect(() => {
    if (setStatus) {
      const status = validate(value, allowEmpty);
      if (focused) {
        setStatus(status === ERROR ? FOCUS : status);
      } else {
        setStatus(status);
      }
    }
  }, [value, allowEmpty, setStatus, focused]);

  return (
    <TextField
      value={value}
      onChange={update}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      {...props}
    />
  );
}

export default ValidatedField;
