import './App.css';

import {
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import consentRoutes from './modules/consent';
import dassRoutes from './modules/dass';
import homeRoutes from './modules/home';
import intakeRoutes from './modules/intake';
import k10Routes from './modules/k10';
import orsRoutes from './modules/ors';
import paymentRoutes from './modules/payment';
import srsRoutes from './modules/srs';
import theme from './theme';

const routes = [
  ...homeRoutes(),
  ...dassRoutes(),
  ...k10Routes(),
  ...orsRoutes(),
  ...srsRoutes(),
  ...intakeRoutes(),
  ...paymentRoutes(),
  ...consentRoutes(),
];

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          {routes}
        </Switch>
      </Router>
    </ThemeProvider>

  );
}

export default App;
