import React, { useEffect, useState } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Pagination from './Pagination';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const titleCase = (str: string) => 
  str.replace(
    /\w\S*/g,
    (txt: string) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

const TextQuestion = ({questionNumber, question, instrument, data, onComplete, classes, required=false} : any) => {
  const progress = 100 * questionNumber / instrument.questions.length;
  const pageKey = question.toUpperCase();
  const instructions = instrument.intro;
  const title = instrument[question];
  const placeholder = instrument[question + '_placeholder'];
  const [answer, setAnswer] = useState(data[pageKey]);
  const complete = (action : string) => {
    onComplete(action, {[pageKey]: answer});
  }
  useEffect(() => {
    setAnswer(data[pageKey]);
  }, [data, questionNumber]);

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <div className={classes.instructions}>
        <Typography variant="body1">
          {instructions}
        </Typography>
      </div>
      <div className={classes.title}>
        <Typography variant="h2" color="primary" className={classes.title}>
          {questionNumber}.
        </Typography>
        <Typography variant="h2" color="primary" className={classes.title}>
          {title}
        </Typography>
      </div>
      <div className={classes.flexRow}>
        <TextField
          placeholder={placeholder}
          value={answer}
          variant="outlined"
          fullWidth={true}
          multiline={true}
          rowsMax={4}
          onChange={(e) => setAnswer(e.target.value)}
        />
      </div>
      <Pagination
        onBack={questionNumber===1 ? undefined : ()=>complete('back')}
        onNext={()=>complete('next')}
        nextDisabled={required && answer===undefined}
      />
      <LinearProgress variant="determinate" value={progress}/>
    </form>
  );
};

export default TextQuestion;
