import React from 'react';
import { useState, useEffect } from 'react';
import { getConsent, saveConsent } from '../../api/form';
import Page from '../../components/Page';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import ConsentForm from './components/ConsentForm';
import Footer from '../../components/Footer';
import ClientConsent from './components/ClientConsent';
import TeleheathConsent from './components/TeleheathConsent';
import Pagination from './components/Pagination';


const useStyles = makeStyles((theme: Theme) => createStyles({
  textCenter: {
    textAlign: 'center',
  },
  blockCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  content: {
    minHeight: 'calc(100vh - 7rem)',
  },
}));

const defaultData = {
  consented: false,
  submitted: false,
  contactName: '',
  contactPhone: '',
};

const ConsentPage = ({history, match} : any) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [data, setData] = useState(defaultData);
  const [pageNo, setPageNo] = useState(1);
  const token = match.params.token;
  const hash = history.location.hash.substring(1);
  const pageNumber = parseInt(hash) || 1;

  useEffect(() => {
    getConsent(token).then((detail) => {
      if (detail.error) {
        setError(detail.error);
      } else {
        setData({complete: false, ...detail});
      }
    }).catch((e) => {
      setError(e);
    }).finally(() => {
      setLoading(false);
    });
  }, [token]);

  useEffect(() => {
    if (data !== defaultData) {
      saveConsent(token, data);
    }
  }, [token, JSON.stringify(data)]);

  const onNext = () => {
    const nextPage = pageNumber + 1;
    history.push(`/consent/${token}#${nextPage}`);
  }

  const onBack = () => {
    const nextPage = pageNumber - 1;
    history.push(`/consent/${token}#${nextPage}`);
  }

  return (
    <Page title={"Client Consent Form : Excel Psychology"}>
      <div className={classes.content}>
        <img src="/ExcelLogo143x59.png" className={classes.blockCenter} alt="Excel Logo"/>
        {pageNumber == 1 && <ClientConsent classes={classes}/>}
        {pageNumber == 2 && <TeleheathConsent classes={classes}/>}
        {pageNumber == 3 && <ConsentForm data={data} setData={setData} onSubmit={setData} busy={loading}/>}
        <Pagination onBack={pageNumber > 1 && onBack} onNext={pageNumber < 3 && onNext} />
      </div>
      <Footer/>
    </Page>
    );
};

export default ConsentPage;
