import React from 'react';
import { Route } from 'react-router-dom';
import IntakePage from './IntakePage';

const routes = () => {
  return [
    <Route key="/intake" exact path="/intake" component={IntakePage}></Route>,
  ]
};
export default routes;
