import React from 'react';
import { useState } from 'react';
import StatusIcon from './StatusIcon';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ValidatedField from './ValidatedField';
import Pagination from './Pagination';
import { CLEAR } from './StatusIcon';

const ContactPage = ({data, onComplete, classes} : any) => {
  const [employer, setEmployer] = useState(data.employer);
  const [employerStatus, setEmployerStatus] = useState(CLEAR);
  const [employmentType, setEmploymentType] = useState(data.employmentType);
  const [occupation, setOccupation] = useState(data.occupation);
  const [occupationStatus, setOccupationStatus] = useState(CLEAR);
  const [allowEmpty, setAllowEmpty] = useState(true);

  const complete = (action : string) => {
    const data = {
      occupation,
      employer,
      employmentType,
    }
    onComplete(action, data);
  }
  // intake form is https://drive.google.com/file/d/0BxhsAjye6VcMMDVMd2pkeURKTjA/view?ths=true
  return (
    <form className={classes.form} noValidate autoComplete="off">
      <div className={classes.formRow}>
        <ValidatedField
          label="Occupation"
          value={occupation}
          onChange={setOccupation}
          setStatus={setOccupationStatus}
          allowEmpty={allowEmpty}
          InputLabelProps={{ shrink: true, }}
        />
        <StatusIcon status={occupationStatus}/>
      </div>

      <div className={classes.formRow}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Employment Status</FormLabel>
          <RadioGroup aria-label="employment status" value={employmentType} onChange={e => setEmploymentType(e.target.value)}>
            <FormControlLabel value="full-time" control={<Radio />} label="Full-time" />
            <FormControlLabel value="part-time" control={<Radio />} label="Part-time" />
            <FormControlLabel value="other" control={<Radio />} label="Other / prefer not to say" />
          </RadioGroup>
        </FormControl>
      </div>

      <div className={classes.formRow}>
        <ValidatedField
          label="Employer"
          value={employer}
          onChange={setEmployer}
          setStatus={setEmployerStatus}
          allowEmpty={allowEmpty}
          InputLabelProps={{ shrink: true, }}
        />
        <StatusIcon status={employerStatus}/>
      </div>

      <Pagination onBack={()=>complete('back')} onNext={()=>complete('next')}/>
    </form>

  );
};

export default ContactPage;
