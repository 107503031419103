import { useEffect, useState } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Pagination from '../../../components/Pagination';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const questions = [
  'I found it hard to wind down',
  'I was aware of dryness of my mouth',
  'I couldn\'t seem to experience any positive feeling at all',
  'I experienced breathing difficulty (eg excessively rapid breathing, breathlessness in the absence of physical exertion)',
  'I found it difficult to work up the initiative to do things',
  'I tended to over-react to situations',
  'I experienced trembling (eg in the hands)',
  'I felt that I was using a lot of nervous energy',
  'I was worried about situations in which I might panic and make a fool of myself',
  'I felt that I had nothing to look forward to',
  'I found myself getting agitated',
  'I found it difficult to relax',
  'I felt down-hearted and blue',
  'I was intolerant of anything that kept me from getting on with what I was doing',
  'I felt I was close to panic',
  'I was unable to become enthusiastic about anything',
  'I felt I wasn\'t worth much as a person',
  'I felt that I was rather touchy',
  'I was aware of the action of my heart in the absence of physical exertion (eg, sense of heart rate increase, heart missing a beat)',
  'I felt scared without any good reason',
  'I felt that life was meaningless',
];

const answers = [
  'Did not apply to me at all',
  'Applied to me to some degree, or some of the time',
  'Applied to me to a considerable degree, or a good part of time',
  'Applied to me very much, or most of the time',
];

const DassQuestion = ({questionNumber, data, onComplete, classes} : any) => {
  const [answer, setAnswer] = useState('');
  const title = questions[questionNumber-1];
  const progress = 100 * questionNumber / questions.length;
  const complete = (action : string) => {
    onComplete(action, {[questionNumber]: answer});
  }
  useEffect(() => {
    setAnswer(data[questionNumber] || '')
  }, [data, questionNumber]);

  return (
    <form className={classes.form} noValidate autoComplete="off">
      <div className={classes.dassInstructions}>
        <Typography variant="body1">
          Please read each statement and select the answer which indicates how much the statement
          applied to you <b>over the past week</b>. There are no right or wrong answers. Do not spend too much time
          on any statement.
        </Typography>
      </div>
      <div className={classes.dassTitle}>
        <Typography variant="h2" color="primary" className={classes.dassTitle}>
          {questionNumber}.
        </Typography>
        <Typography variant="h2" color="primary" className={classes.dassTitle}>
          {title}
        </Typography>
      </div>
      <div className={classes.formRow}>
        <FormControl component="fieldset">
          <RadioGroup aria-label="answer" value={parseInt(answer)} onChange={(e)=>setAnswer(e.target.value)}>
            {answers.map((q, i) =>
              <FormControlLabel key={i} value={i+1} control={<Radio />} label={q} className={classes.dassAnswer}/>
            )}
          </RadioGroup>
        </FormControl>
      </div>
      <Pagination
        onBack={questionNumber===1 ? undefined : ()=>complete('back')}
        onNext={()=>complete('next')}
        nextDisabled={answer===''}
      />
      <LinearProgress variant="determinate" value={progress}/>
    </form>
  );
};

export default DassQuestion;
