import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { getDass21, saveDass21 } from '../../api/form';
import { useEffect, useState } from 'react';

import DassQuestion from './components/DassQuestion';
import ErrorMessage from '../../components/ErrorMessage';
import Page from '../../components/Page';
import React from 'react';
import SubmitPage from '../../components/SubmitPage';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => createStyles({
  textCenter: {
    textAlign: 'center',
  },
  blockCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  form: {
    marginTop: '1rem',
    maxWidth: 600,
    minHeight: '20rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  formRow: {
    '& > *': {
      margin: theme.spacing(1),
      display: 'inline-block',
      veriticalAlign: 'middle',
    },
    '& label:nth-of-type(odd)': {
      backgroundColor: '#f0f0f0',
    },
  },
  content: {
    minHeight: 'calc(100vh - 7rem)',
  },
  dassInstructions: {
    marginBottom: '1rem',
  },
  dassTitle: {
    minHeight: '5rem',
    display: 'flex',
    flexDirection: 'row',
    '& > *:first-child': {
      marginRight: '1rem',
    },
  },
  dassAnswer: {
    minHeight: '2rem',
  }
}));

const defaultData = {nextPage: 1};
const DassPage = ({history, match} : any) => {
  const questionCount = 21;
  const pageCount = questionCount + 1;
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(defaultData);
  const [error, setError] = useState(undefined);

  const hash = history.location.hash.substring(1);
  const token = match.params.token;
  const pageNumber = Math.min(parseInt(hash), data.nextPage) || 1;

  useEffect(() => {
    getDass21(token).then((detail) => {
      if (detail.error) {
        setError(detail.error);
      } else {
        setData({nextPage: 1, ...detail});
      }
    }).catch((e) => {
      setError(e);
    }).finally(() => {
      setLoading(false);
    });
  }, [token]);

  useEffect(() => {
    if (data !== defaultData) {
      saveDass21(token, data);
    }
  }, [token, JSON.stringify(data)]);

  const onComplete = (action: string, pageData: object) => {
    let nextPage = pageNumber;
    if (action === 'back' && pageNumber > 1) {
      nextPage -= 1;
    }
    if (action === 'next' && pageNumber < pageCount) {
      nextPage += 1;
    }
    setData({...data, ...pageData, ...{nextPage: Math.max(data.nextPage, nextPage)}});
    history.push(`/dass/${token}#${nextPage}`);
  }

  return (
    <Page title={"DASS21 : Excel Psychology"}>
      <div className={classes.content}>
        <img src="/ExcelLogo143x59.png" className={classes.blockCenter} alt="Excel Logo"/>
        <Typography variant="h1" className={classes.textCenter}>
          DASS 21
        </Typography>
        {!loading && error && <ErrorMessage error={error}/>}
        {!loading && !error && pageNumber <= questionCount && <DassQuestion questionNumber={pageNumber} data={data} classes={classes} onComplete={onComplete}/>}
        {!loading && !error && pageNumber > questionCount && <SubmitPage data={data} classes={classes} onComplete={onComplete}/>}
      </div>
    </Page>
    );
};

export default DassPage;
