import React from 'react';
import { Route } from 'react-router-dom';
import ConsentPage from './ConsentPage';

const routes = () => {
  return [
    <Route key="/consent" exact path="/consent/:token" component={ConsentPage}></Route>,
  ]
};
export default routes;
