
import React from 'react';
import Typography from '@material-ui/core/Typography';

const ClientConsent = ({classes}: any) => {
  return (
    <div>
      <Typography variant="h1" className={classes.textCenter}>
        Client Consent Form
      </Typography>
      <Typography variant="body1" component="div">
        <p>
          In order for Excel Psychology to provide appropriate mental health care to you, we are seeking your consent to collect essential personal information about you. Please read this information carefully.
        </p>
        <p>
          Your psychologist may ask about your medical history and personal details so that they can properly assess, diagnose and provide the best therapy for your mental health care. In some cases it may be necessary to request previous records from other practitioners outside Excel Psychology.
        </p>
        <p>
          This information may be used in the following ways:
        </p>
        <ul>
          <li>
            Our administrative purposes,
          </li>
          <li>
            Billing purposes including compliance with requirements from Medicare, Private Health Insurance, etc, including contacting Medicare to confirm your records or details,
          </li>
          <li>
            Disclosure to others involved in your health care, including treating doctors and specialists outside of Excel Psychology - this may occur through referral to other health practitioners,
          </li>
          <li>
            Disclosure to a third party if a medico-legal issue arises, including your file being subpoenaed,
          </li>
          <li>
            NOTE: Information provided by clients is confidential; however, psychologists may be required to breach confidentiality.  This would only occur if the client is a danger to themselves or others.
          </li>
        </ul>
        <p>
          I understand and confirm that:
        </p>
        <ul>
          <li>
            I have read the information above and understand the reasons why my information is collected,
          </li>
          <li>
            I am aware that this practice has a confidentiality policy on handling patient information,
          </li>
          <li>
            I understand that I am not obligated to provide information requested of me, but failure to do so may compromise the quality of mental health care and treatment received,
          </li>
          <li>
            I am aware of my right to access information collected about me, except in some circumstances where access might be legitimately withheld (please note an explanation will be given in these circumstances),
          </li>
          <li>
            I understand that if my information is to be used for any other purpose other than set out above, my further consent will be obtained,
          </li>
          <li>
            I consent to the handling of my information by this practice for the purpose set out above, subject to any limitations on access or disclosure that I notify the practice of,
          </li>
          <li>
            I have been informed that 24 hours notice of cancellation is required or a fee may be charged,
          </li>
          <li>
            I am aware that any services outside of therapy, such as report writing or third party liaison will be charged at the treating psychologists standard hourly rate, and is not covered by Medicare or private health insurances,
          </li>
          <li>
            I will advise Excel Psychology if I have seen another Psychologist in this calendar year.
          </li>
        </ul>
      </Typography>
    </div>
  );
}

export default ClientConsent;
