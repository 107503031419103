import React from 'react';
import OkIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Clear';
import FocusIcon from '@material-ui/icons/ArrowBack';

export const CLEAR = undefined;
export const ERROR = 'error';
export const FOCUS  = 'focus';
export const OK = 'ok';

type StatusIconProps = {
  status?: string,
}

const StatusIcon = ({status} : StatusIconProps) => {
  return (
    <div>
      {status === ERROR && <ErrorIcon color="error"/>}
      {status === FOCUS && <FocusIcon color="primary"/>}
      {status === OK && <OkIcon/>}
    </div>
  );
}

export default StatusIcon;
